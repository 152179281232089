<template>
  <div v-if="isPermission">
    <CCard class="shadow-sm">
      <CCardBody>
        <CRow>
          <CCol sm="1" col="2" class="text-center"><img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
          </CCol>
          <CCol sm="9" col="7">
            <select class="custom-select text-dark" v-model="shop" disabled>
              <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                {{ shop.shopName }} - {{ shop.branchName }}
              </option>
            </select>

            <div v-if="validateShop" class="text-danger">
              {{ validateShop }}
            </div>
          </CCol>
          <div class="col-md-2 col-sm-2 col-3 text-right">
            <CButton block color="light" @click="getback()">
              <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
            </CButton>
          </div>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard class="shadow-sm">
      <CCardBody>
        <CAlert color="success" v-if="isSuccess === true">
          {{ $t('saveSuccess') }} !!
        </CAlert>
        <div class="row">
          <div class="col-md-10 col-sm-9 col-9">
            <h2 class="font-weight-normal">
              {{ $t('Channels') }}
            </h2>
            <h2 class="font-weight-normal">
              <span class="h3">
                {{ $t('productName') }} :
                {{ SKUName }}
              </span>
            </h2>
          </div>
          <div class="col-md-2 col-sm-3 col-3 text-right">
            <CButton
              block
              color="success"
              @click="confirmModal = true"
              v-if="isEditData"
            >
              {{ $t('save') }}
            </CButton>
          </div>
        </div>
        <hr />
        <CRow>
          <CCol sm="12" lg="12">
            <div style="overflow-x: auto">
              <table class="table table-hover" style="min-width: max-content">
                <thead class="table-borderless">
                  <tr>
                    <th style="width:70%" class="text-dark font-weight-normal">{{ $t('Channels') }}</th>
                    <th style="width:30%" class="text-dark font-weight-normal text-right">
                      {{ $t('enable') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, index) in channelsList"
                    :key="index"
                    :v-model="channelsList"
                  >
                    <td style="width:70%" class="text-dark font-weight-normal">
                      {{ value.name }}
                    </td>
                    <td style="width:30%" class="text-dark font-weight-normal text-right">
                      <CSwitch
                        v-if="isEditData"
                        color="success"
                        :v-model="value.enable"
                        :value="value.enable"
                        :checked.sync="value.enable"
                      />
                      <CSwitch
                        v-else
                        color="success"
                        :v-model="value.enable"
                        :value="value.enable"
                        :checked.sync="value.enable"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CModal color="success" :show.sync="confirmModal" centered>
        <div v-if="alertError">
          <h4 class="text-center text-danger">{{ textError }}</h4>
          <br />
          <h5 class="text-center text-danger">{{ textMessage }}</h5>
        </div>
        <div v-else>
          <br />
          <h4 class="text-center">{{ $t('confirmSaveImgDialog') }}</h4>
          <br />
        </div>
        <template #footer>
          <CRow class="justify-content-around col-md-12">
            <CCol md="4" lg="4" col="6" sm="6">
              <CButton
                block
                v-if="loadingButton === true"
                color="success"
                v-on:click="savedata"
              >
                {{ $t('save') }}
              </CButton>
              <CButton
                v-else-if="loadingButton === false"
                block
                color="success"
                disabled
              >
                <CSpinner color="white" size="sm" /> {{ $t('save') }}
              </CButton>
            </CCol>
            <CCol lg="4" md="4" col="6" sm="6">
              <CButton block color="light" @click="confirmModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'
import permis from '@/util/permission'

export default {
  components: {},
  data() {
    return {
      isSuccess: false,
      SKUName: '',
      data: {},
      validateShop: false,
      shop: this.$store.getters.shopObjectId,
      productcode: '',
      SKU: [],
      confirmModal: false,
      loadingSaveimg: true,
      titlename: '',
      showbuttonsave: false,
      loadingButton: true,
      deleteModal: false,
      loading: true,
      textError: '',
      textMessage: '',
      alertError: false,
      isError: 0,
      channels: {},
      channelsList: {},
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      let path = '/product'
      return permis.findPermissionRead('product', path)
    },
    isEditData() {
      let path = '/product'
      return permis.findPermissionEdit('product', path)
    },
    isDeleteData() {
      let path = '/product'
      return permis.findPermissionRemove('product', path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
  },
  created() {
    this.getAllMarketingChannels()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  methods: {
    getAllMarketingChannels() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: this.shopObjectId }
      local({
        url: '/api/v1.0/' + uid + '/Shop/marketingchannels/' + shopObjectId,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.channels = res.data.data
        this.getProduct()
      })
    },
    getback() {
      let productSKUObjectId = this.$route.params.productSKUObjectId;
      
      if (this.$route.path.includes('/product/productdetail/channels/')) {
        this.$router.push({
          name: 'ProductDetail',
          params: { productSKUObjectId },
        })
      } else if (this.$route.path.includes('/ecommerce/ProductList/channels/')) {
        this.$router.push({
          name: 'EcommerceProductDetail',
          params: { productSKUObjectId },
        })
      }
    },
    getProduct() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: '',
      }
      local
        .get(
          '/api/v1.0/' +
            uid +
            '/ProductSKU/data/' +
            this.$route.params.productSKUObjectId,
          { params }
        )
        .then((res) => {
          this.data = res.data.data.document
          if (res.data.data.document.SKUName !== undefined) {
            this.SKUName = res.data.data.document.SKUName
          }
          let dataChannels = this.data.channels
          let market = this.channels
          let channels = {}
          if (dataChannels == undefined) {
            channels = market
          } else {
            channels = Object.assign(market, dataChannels)
          }
          this.channelsList = channels
          this.loadingButton = true
        })
    },
    savedata() {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      this.loadingButton = false
      let data = this.data
      Object.assign(data, { channels: this.channelsList })
      const headers = { shopObjectId: shopObjectId }
      delete data._id
      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productsku/edit',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
        headers: headers,
      })
        .then((response) => {
          setTimeout(() => {
            if (response.data.error.code === 4000) {
              this.textError = this.$i18n.t('failedToSave')
              this.textMessage = response.data.error.message
              this.alertError = true
              this.loadingButton = false
            } else {
              this.loadingButton = true
              this.loading = true
              this.confirmModal = false
              this.isSuccess = true
            }
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3abc98;
  background-color: #3abc98;
}
.cursor {
  cursor: pointer;
}
</style>
